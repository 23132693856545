<template>
    <div class="FacilityDetail">
        <main-header menuActive="facility" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>시설관리</h2>
                </div>

                <div class="row">
                    <table class="table table-profile">
                        <colgroup>
                            <col width="10%" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">구분</th>
                            <td class="text-left">
                              <label v-for="(data, index) in list_fasility_code" :key="index"
                                     class="mrg-right-10"
                              >
                                <input type="radio"
                                       :value="data.code"
                                       v-model="f_type_code"
                                />
                                {{data.name}}
                              </label>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">시설명</th>
                            <td class="text-left">
                                <input type="text" class='input type2 width-100' v-model="f_fasility_name" />
                            </td>
                        </tr>
<!--                        <tr>-->
<!--                            <th class="field">지역</th>-->
<!--                            <td class="text-left">-->
<!--                              <select class="select_ansimi width150"-->
<!--                                      v-model="f_region"-->
<!--                              >-->
<!--                                <option v-for="(data, index) in list_region" :key="index" :value="data.code">-->
<!--                                  {{data.name}}-->
<!--                                </option>-->
<!--                              </select>-->
<!--                            </td>-->
<!--                        </tr>-->
                        <tr>
                            <th class="field">총병상</th>
                            <td class="text-left">
                                <input type="text" class='input type2 width-100' v-model="f_total_room"  onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" />
                            </td>
                        </tr>
                        <tr>
                            <th class="field">담당자</th>
                            <td class="text-left">
                              <select class="select_ansimi width150"
                                      v-model="f_admin_idx"
                              >
                                <option v-for="(data, index) in list_admin" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>
                            </td>
                        </tr>
						<tr>
							<th class="field">메모</th>
							<td class="text-left">
								<input type="text" class='input type2 width-100' v-model="f_memo" />
							</td>
						</tr>
						<tr>
							<th class="field">노출순서</th>
							<td class="text-left">
								<input type="text" class='input type2 width-100' v-model="f_fasility_display_rank" onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" />
							</td>
						</tr>
                        </tbody>
                    </table>
                </div>

                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="listBackFunc()">목록</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';

export default {
    name: 'FacilityDetail',
    data() {
        return {
            f_type_code : '',                   // 시설구분
            list_fasility_code : [],            // 시설구분 리스트
            f_fasility_name:'',                 // 시설명
            f_region : '',                      // 지역별 정보
            list_region : [],                   // 지역별 리스트
            f_total_room : 0,                   // 총병상
            f_admin_idx : '',                   // 담당자
			list_admin : [],                    // 담당자 리스트
			f_memo : '',                        // 메모
			f_idx:'',                           // 고유번호
			f_fasility_display_rank : 999       // 정렬순서
        }
    },
    components: { mainHeader, mainFooter },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        this.$nextTick(function() {

            if(!this.storageGet('pageInfo')) this.storageSet('pageInfo', this.$route.params.data);

            let info = this.storageGet('pageInfo', 'info');

            if (info.f_idx){
                let params = `{"fIdx":${info.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocFacility/detail?params=' + queryString, null, 'initInfo');
                /*
                this.f_type_code = info.f_type_code;
                this.f_fasility_name = info.f_fasility_name;
                this.f_region = info.f_region_code;
                this.f_total_room = info.f_total_room;
                this.f_admin_idx = info.f_admin_idx;
                this.f_memo = info.f_memo;
                this.f_fasility_display_rank = info.f_fasility_display_rank;
                this.f_idx = info.f_idx;
                */
            }
        });
    },
    methods: {
        init(){
            this.ajaxGet('nocCommon/code?params=', null, 'initResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'patientCreateCode');
            this.ajaxPost('', formData,'initResult');
            */
        },
        initResult(response){
            let result = response;

            // 구분
            this.list_fasility_code = result.fasilityCodeList;

            // 지역별
            this.list_region = result.regionList;
            this.list_region.unshift({ code:'', name:'선택' });

            // 담당자
            this.list_admin = result.adminList;
            this.list_admin.unshift({ code:'', name:'선택' });

        },
        initInfo(response){
            let result = response.list;
            const pageInfo = this.storageGet('pageInfo') || {};  // 기존 데이터 가져오기
            pageInfo.info = result;                                   // 새로운 데이터를 기존 데이터의 info 속성에 추가
            this.storageSet('pageInfo', pageInfo);              // 기존 데이터에 새로운 데이터 추가 후 저장

            let info = this.storageGet('pageInfo', 'info');

            console.log(info);

            if (info) {
                this.f_type_code = info.ftypeCode;
                this.f_fasility_name = info.ffasilityName;
                // this.f_region = info.f_region_code;
                this.f_total_room = info.ftotalRoom;
                this.f_admin_idx = info.fadminIdx;
                this.f_memo = info.fmemo;
                this.f_fasility_display_rank = info.ffasilityDisplayRank;
                this.f_idx = info.fidx;
            }
        },
        sendFunc(){

            if (!this.f_type_code){
                this.showAlert('', '시설구분을 선택해 주세요.');
                return false;
            }

            // if (!this.f_fasility_name){
            //     this.showAlert('', '시설명을 입력해 주세요.');
            //     return false;
            // }

            // if (!this.f_region){
            //     this.showAlert('', '지역을 선택해 주세요.');
            //     return false;
            // }
            if (this.f_total_room === '' ){
                this.showAlert('', '총병상을 입력해 주세요.');
                return false;
            }

            let data = {
                "fTypeCode" : this.f_type_code,
                "fFasilityName" : this.f_fasility_name,
                "fTotalRoom" : this.f_total_room,
                "fMemo": this.f_memo,
                "fFasilityDisplayRank": this.f_fasility_display_rank,
                "adminId": this.userDataAdminId
            };
            if (this.f_idx) {
                data.fIdx = this.f_idx;
            }

            if (this.f_idx) {
                this.ajaxPost('nocFacility/update', data,'sendFuncResult');
            } else {
                this.ajaxPost('nocFacility/regist', data,'sendFuncResult');
            }

            /*
            let formData = new FormData();
            if (this.f_idx){
                formData.append('api_name', 'fasilityUpdate');
                formData.append('f_idx', this.f_idx);
            }else{
                formData.append('api_name', 'fasilityCreate');
            }
            formData.append('f_type_code', this.f_type_code);
            formData.append('f_fasility_name', this.f_fasility_name);
            formData.append('f_region', this.f_region);
            formData.append('f_total_room', this.f_total_room);
            formData.append('f_admin_idx', this.f_admin_idx);
            formData.append('f_memo', this.f_memo);
            formData.append('f_fasility_display_rank', this.f_fasility_display_rank);
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'sendFuncResult');
            */
        },
        sendFuncResult(response) {
            this.showAlert('', '저장 되었습니다.',"listBackFunc");
        },
        listBackFunc(){
            this.$router.push({ name: 'AdminFacilityList', params: this.storageGet('pageInfo')})
        }
    },
    destroyed(){
        this.storageRemove('pageInfo');
    }
}
</script>

<style scoped>
</style>
